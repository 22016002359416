import React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import SubHeader from "../components/subHeader"
import ContactFormSection from "../components/contactFormSection"
import InstagramSection from "../components/instagramSection"
import { Container, Grid } from "@mui/material"
import ScrollAnimation from "react-animate-on-scroll"
import DetailBlock from "../components/detailblock"
import Phone from "../images/svgs/phone.svg"
import Loc from "../images/svgs/loc.svg"

// temporary
import image from "../images/trial.jpg"

const Contact = () => {
  return (
    <Layout>
      <Seo title="Contact" />
      <SubHeader title="Contact Us" image={image} />
      <section className="contactInfo">
        <Container>
          <div className="formContainer infoBox details">
            <Grid container spacing={10} className="detailContainer">
              <Grid item xs={12} md={6} className="detail">
                <ScrollAnimation animateIn="animate__fadeIn" animateOnce={true}>
                  <DetailBlock
                    title="Get In Touch"
                    text={
                      <ul>
                        <li>
                          <a href="tel:+61474350663">0474 350 663</a>
                        </li>
                        <li>
                          <a href="mailto:office@jandakotheli-co.com.au">
                            office@jandakotheli-co.com.au
                          </a>
                        </li>
                      </ul>
                    }
                    icon={<Phone />}
                  />
                </ScrollAnimation>
              </Grid>
              <Grid item xs={12} md={6} className="detail">
                <ScrollAnimation animateIn="animate__fadeIn" animateOnce={true}>
                  <DetailBlock
                    title="Find Us"
                    text={
                      <ul>
                        <li>
                          <a href="https://goo.gl/maps/LCLSsahX8vySoocF6">
                            8 Bell Court, Jandakot Airport
                          </a>
                        </li>
                        <li>
                          <a href="https://goo.gl/maps/LCLSsahX8vySoocF6">
                            Western Australia, 6164
                          </a>
                        </li>
                      </ul>
                    }
                    icon={<Loc />}
                  />
                </ScrollAnimation>
              </Grid>
            </Grid>
          </div>
        </Container>
      </section>
      <ContactFormSection />
      <InstagramSection />
    </Layout>
  )
}

export default Contact
